import React, { Fragment, useState } from 'react';
import { Button, Container, Grid, TextField, Typography } from '@mui/material';
import { IoMdCloudUpload } from 'react-icons/io';
import { toast } from 'react-toastify';
import axios from '../../../config/server'

const RegisterHospital = () => {
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [hospitalData, setHospitalData] = useState({
    name: '',
    address: '',
    ownerName: '',
    mobile: '',
    email: '',
    password: '',
    logo: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHospitalData({
      ...hospitalData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setHospitalData({
      ...hospitalData,
      logo: file,
    });
  };

  

  const validateForm = (formData) => {

    if (!formData.name) {
      toast.error('Hospital Name is required')
      return true
    }

    if (!formData.address) {
      toast.error('Address is required')
      return true
    }

    if (!formData.ownerName) {
      toast.error("Owner's Name is required")
      return true
    }

    if (!formData.mobile) {
      toast.error('Mobile is required')
      return true
    } else if (!/^[0-9+]{10,}$/.test(formData.mobile)) {
      toast.error('Mobile should be a minimum of 10-digit number');
      return true;
    }
    

    if (!formData.email) {
      toast.error('Email is required')
      return true
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      toast.error('Invalid email address')
      return true
    }

    if (!formData.password) {
      toast.error('Password is required')
      return true
    }

    if (!formData.logo) {
      toast.error('Logo is required')
      return true
    }

  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm(hospitalData);
    setErrors(validationErrors);

    if (validationErrors) {
      return true;
    }

    try {
      toast.info('Submitting...');
      const response = await axios.post('/hospitals/register', hospitalData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      if (response.status === 201) {
        toast.success(response.data.message)
        setHospitalData({
          name: '',
          address: '',
          ownerName: '',
          mobile: '',
          email: '',
          password: '',
          logo: null,
        })
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(error.response.data.message)
    }
    setIsSubmitted(true);
  };

  return (
    <div className="mt-5 pt-5 pb-5" style={{background:'#dddddd'}}>
    <Container maxWidth="xlg" className='mx-auto my-5'>
      <form onSubmit={handleSubmit} style={{width: '80%'}} className='mx-auto hospital-wrapper' encType='multipart/form-data'>
        <Typography variant="h4" gutterBottom>
          Register a Hospital
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>

            <TextField
              fullWidth
              label="Hospital Name"
              name="name"
              value={hospitalData.name}
              onChange={handleChange}
            // required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Address"
              name="address"
              value={hospitalData.address}
              onChange={handleChange}
            // required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Owner's Name"
              name="ownerName"
              value={hospitalData.ownerName}
              onChange={handleChange}
            // required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type='number'
              fullWidth
              label="Mobile"
              name="mobile"
              value={hospitalData.mobile}
              onChange={handleChange}
            // required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={hospitalData.email}
              onChange={handleChange}
            // required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Password"
              name="password"
              type="password"
              value={hospitalData.password}
              onChange={handleChange}
            // required
            />
          </Grid>
       
          <Grid item xs={12} sm={4}>
            <input
              accept="image/*"
              id="logo-upload"
              type="file"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <label htmlFor="logo-upload">
              <Button
                variant="outlined"
                component="span"
                startIcon={<IoMdCloudUpload />}
              >
                Upload Logo
              </Button>
            </label>
          </Grid>
        </Grid>
        <Button type="submit" variant="contained" color="primary">
          Register
        </Button>
      </form>
    </Container>
  </div>
  );
};

export default RegisterHospital;
