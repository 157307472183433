import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
// import { H5 } from '../../AbstractElements';
// import { MonthlyProfitsGrowth, MonthlyProfitsTitle } from '../../Constant';
// import { MonthlyProfitsChartData } from '../Data/Ecommerce/Chart';
import { LightCardData2 } from '../Data/DefaultDashboard/index';
import LightCardBox from './Common/LitghtCard';

const UsersShowCase = ({series}) => {
  const usersData = [
    series[0]?.totalPatients,
    series[0]?.totalUsers,
    series[0]?.totalNurses,
  ]
  return (
    <Card>
      
      <CardBody className='pt-0 my-auto'>
        <div className='monthly-profit'>
     <Col xl='12' md='4' sm='12' className='box-col-5 p-0 mt-4'>
            <Row className='g-sm-3 g-2'>
              {LightCardData2?.map((data, i) => (
                <Col key={i} md='12'>
                   <LightCardBox key={i} data={[ {data: { ...data, price:usersData[i]} }]} /> 
                </Col>
              ))}
            </Row>
          </Col>
        </div>
      </CardBody>
    </Card>
  );
};

export default UsersShowCase;
