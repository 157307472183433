import React, { Fragment, useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Btn, H4 } from "../../../AbstractElements";
import { tableColumns } from "../../../Data/Table/Defaultdata";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { AddSubscription, Close, SaveChanges } from "../../../Constant";
import axios from "../../../config/server";
import { Grid, Tooltip, Typography } from "@mui/material";
import AddCardIcon from '@mui/icons-material/AddCard';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { pink } from '@mui/material/colors';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { toast } from "react-toastify";
import { BiSolidToggleLeft } from "react-icons/bi";
import { BiSolidToggleRight } from "react-icons/bi";
import { Loader } from "react-feather";


const DataTableComponent = () => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelete, setToggleDelete] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [data, setData] = useState([]);
    const [singleHospitalData, setSingleHospitalData] = useState([]);
    const [subScriptionItem, setSubScriptionItem] = useState({});
    const [Large, setLarge] = useState(false);
    const [subscriptionModel, setSubscriptionModel] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const LargeModaltoggle = () => setLarge(!Large);
    const SubscriptionModelToggle = () => setSubscriptionModel(!subscriptionModel);

    const hospitalData = [];
    async function fetchHospitalData() {
        try {
            const response = await axios.get("admin/hospitals");
            setData(response.data.data);
            hospitalData.push(response.data.data);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error?.response?.message);
        }
    }
    useEffect(() => {
  
        fetchHospitalData();
    }, []);


    const handleDateChange = (date, setDateFunction) => {
        setDateFunction(date);
    };



    const SingleTableColumn = [
        {
            name: "Hospital",
            selector: row => row['hospitalName'],
            sortable: true,
        },
        {
            name: "Nurses",
            selector:  row => row['nurse'],
            sortable: true,
        },
        {
            name: "Patients",
            selector: row => row['patient'],
            sortable: true,
        },
        {
            name: "Users",
            selector: row => row['user'],
            sortable: true,
        },
    ];

    const handleView = () => {
        setToggleDelete(true);
        setSelectedRows([]);
        LargeModaltoggle();
    };

    const handleSubscription = (item) => {
        console.log('item', item);
        setSubScriptionItem(item);
        SubscriptionModelToggle();
    }

    const handleActivation = async (item, statusTochange) => {
        try {
            setIsLoading(true);
            const response = await axios.patch(`admin/hospitals/${item._id}`, { active: statusTochange === "activate" ? false : true });
            if (response.status === 200) {
                toast.success(response.data.message);
                fetchHospitalData();
            }else{
                toast.error(response.data.message);
            }

        } catch (error) {
            console.log('error', error);
            toast.error(error?.response?.data?.message);
        }
    }

    let tableData;
    let newData;

    tableData = data?.map(item => {
        return {
            id: item._id,
            name: item.name,
            patients: item?.patients?.length,
            nurses: item?.nurses?.length,
            users: item?.users?.length,
            address: item.address,
            ownerName: item.ownerName,
            mobile: item.mobile,
            email: item.email,
            status: item.active,
            createdAt: new Date(item.createdAt).toLocaleDateString(),
            subscriptionsStartDate: item?.subscriptions ? new Date(item?.subscriptions?.startDate).toLocaleDateString() : 'NA',
            subscriptionsEndDate: item?.subscriptions ? new Date(item?.subscriptions?.endDate).toLocaleDateString() : 'NA',
            subscriptionStatus:
            item?.subscriptions ? 
            new Date(item?.subscriptions?.endDate) > new Date() ? 
            <Tooltip title="Subscription Active" placement="right-start"> 
            <> <DoneAllIcon color="success" /></> </Tooltip> : 
            <Tooltip title="Subscription Expired" placement="right-start">
            <><SyncProblemIcon style={{ cursor: 'pointer'}} color="warning" onClick={() => handleSubscription(item)} /> </> </Tooltip> : 
            <Tooltip title="Add Subscription" placement="right-start">
            <><AddCardIcon style={{ cursor: 'pointer'}} sx={{ color: pink[500] }} onClick={() => handleSubscription(item)} /> </> </Tooltip>,
            action: isLoading === true ? <Loader /> : item?.active === false ? <BiSolidToggleLeft color="gray" style={{ cursor: 'pointer', fontSize: '2.3em' }} onClick={() => handleActivation(item, "deactivate")} /> : <BiSolidToggleRight color="green" style={{ cursor: 'pointer', fontSize: '2.3em', alignItems: 'center' }} onClick={() => handleActivation(item, "activate")} />
        };
    })


    const HandleAddSubscription = async () => {
        try {
            if (startDate > endDate || startDate.toString() === endDate.toString()) {
                return toast.error('End date should not be less or equal to Start date');
            }
            const data = {
                hospitalId: subScriptionItem._id,
                startDate: startDate,
                endDate: endDate
            }
            toast.info('Submitting...');
            const response = await axios.post('/subscriptions/', data)
            if (response.status === 201) {
                toast.success(response.data.message)
                setSubscriptionModel(false);
                setSubScriptionItem({});
                setEndDate(new Date());
                setData(response.data.data)
            } else {
                toast.error(response.data.message)
            }
        } catch (error) {
            toast.error(error?.response?.message);
        }
    }

    const handleCloseSubscriptionModel = () => {
        setSubscriptionModel(false);
        setSubScriptionItem({});
        setEndDate(new Date());
        setStartDate(new Date());
    }


    const handleRowSelected = useCallback(async (state) => {
        setSelectedRows(state.selectedRows);
        let singletablelData = [];
        const currentlySelectedRows = state.selectedRows;
        const selectedHospitals = [];

        for (const selectedRow of currentlySelectedRows) {
            const hospitalId = selectedRow.id;

            const selectedHospital = hospitalData[0].find(
                (item) => item._id.toString() === hospitalId.toString()
            );

            if (selectedHospital) {
                selectedHospitals.push(selectedHospital);
            }
        }

        if (selectedHospitals.length > 0) {
            for (const item of selectedHospitals) {
                const hospitalName = item?.name;
                const nurses = item?.nurses || [];
                const patients = item?.patients || [];
                const users = item?.users || [];
                const maxLength = Math.max(
                    nurses.length,
                    patients.length,
                    users.length
                );

                for (let i = 0; i < maxLength; i++) {
                    singletablelData.push({
                        hospitalName,
                        nurse: nurses[i]?.name || "",
                        patient: patients[i]?.name || "",
                        user: users[i]?.name || "",
                    });
                }
            }

            setSingleHospitalData(singletablelData);
        }
    }, []);

    const filteredTableData = tableData.filter(item => {
        return (
            item.name.toLowerCase().includes(searchText.toLowerCase()) ||
            item.ownerName.toLowerCase().includes(searchText.toLowerCase()) ||
            item.email.toLowerCase().includes(searchText.toLowerCase()) ||
            item.mobile.toLowerCase().includes(searchText.toLowerCase()) ||
            item.address.toLowerCase().includes(searchText.toLowerCase())
        );
    });


    return (
        <Fragment>
            {selectedRows.length !== 0 && (
                <div
                    className={`d-flex align-items-center justify-content-between bg-light-info p-2`}
                >
                    <H4 attrH4={{ className: "text-muted m-0" }}>Do you want to view? </H4>
                    <Btn attrBtn={{ color: "success", onClick: () => handleView() }}>
                        View
                    </Btn>
                </div>
            )}
            <input
                type="text"
                placeholder="Search..."
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                style={{ margin: '10px 0', padding: '5px' }}
            />
            <DataTable
                data={filteredTableData}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
                selectableRows
                onSelectedRowsChange={handleRowSelected}
                clearSelectedRows={toggleDelete}
                text={{ search: searchText }}
            />

            <Modal isOpen={Large} toggle={LargeModaltoggle} size="lg" centered>
                <ModalHeader toggle={LargeModaltoggle}>
                    <Typography>Hospital Details</Typography>
                </ModalHeader>
                <ModalBody className="modal-body">
                    <DataTable
                        data={singleHospitalData}
                        columns={SingleTableColumn}
                        striped={true}
                        center={true}
                        pagination
                    />
                </ModalBody>
                <ModalFooter>
                    <Btn attrBtn={{ color: "secondary", onClick: LargeModaltoggle }}>
                        {Close}
                    </Btn>
                </ModalFooter>
            </Modal>
            <Modal isOpen={subscriptionModel} toggle={SubscriptionModelToggle} size='lg' centered>
                <ModalHeader toggle={SubscriptionModelToggle}>
                    Add Subscription
                </ModalHeader>
                <ModalBody className="modal-body">
                    <Grid container spacing={3} className="text-center">
                        <Grid item xs={12} sm={6}>
                            <label htmlFor="startDatePicker" className='me-3' aria-label="Start Date">
                                Start Date
                            </label>
                            <DatePicker id="startDatePicker" onChange={(date) => handleDateChange(date, setStartDate)} value={startDate} ariaLabel='Start Date' />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <label htmlFor="endDatePicker" className='me-3' aria-label="End Date">
                                End Date
                            </label>
                            <DatePicker id="endDatePicker" onChange={(date) => handleDateChange(date, setEndDate)} value={endDate} ariaLabel='End Date' />
                        </Grid>
                    </Grid>
                </ModalBody>
                <ModalFooter>
                    <Btn attrBtn={{ color: 'secondary', onClick: handleCloseSubscriptionModel }} >{Close}</Btn>
                    <Btn attrBtn={{ color: 'primary', onClick: HandleAddSubscription }}>{AddSubscription}</Btn>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};
export default DataTableComponent;
