import React from 'react';

import { H6 } from '../../AbstractElements';
// import { DailyDropdown } from '../../../Constant';
import SvgIcon from '../../Components/Common/Component/SvgIcon';
// import DropdownCommon from '../../Common/Dropdown';

const LightCardBox = ({ data}) => {
  const userData = data.data
  return (
    <div className=' balance-card widget-hover'>
      <div className='svg-box'>
        <SvgIcon className='svg-fill' iconId={userData.icon} />
      </div>
      <div>
        <span className='f-light '>{userData.price}</span>
        <H6 attrH6={{ className: 'mt-1 mb-0' }}>{userData.title}</H6>
      </div>
      {/* <div className='ms-auto text-end'>
        <DropdownCommon dropdownMain={{ className: 'icon-dropdown', direction: 'start' }} options={DailyDropdown} iconName='icon-more-alt' btn={{ tag: 'span' }} />
        {data.gros && <span className={`d-inline-block mt-1 font-${data.color}`}>{data.gros}</span>}
      </div> */}
    </div>
  );
};

export default LightCardBox;
