import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../AbstractElements';
import TopHeroSection from './TopHeroSection';
import DataTables from '../Components/Tables/DataTable';

const Dashboard = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle='Duty Roaster Dashboard' parent ='Dashboard' />
      <Container fluid={true}>
        <Row className='size-column'>
          <TopHeroSection />
          <DataTables />
        </Row>
      </Container>
    </Fragment>
  );
};

export default Dashboard;
