import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import UsersShowCase from './UsersShowCase';
import HospitalsChart from './HospitalsChart';
import axios from '../config/server';
import { useEffect } from 'react';

const TopHeroSection = () => {
  const [apiData, setApiData] = useState([]);
const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);
  async function fetchData() {
    try {
      const response = await axios.get('admin/data/dashboard');
      setData(response.data.data);
      setApiData(response.data.data.hospitalByMonth);
      setLoading(false);
     } catch (error) {
      console.error('Error fetching data:', error?.response?.message);
    }
  }
  useEffect(() => {
  fetchData()
  }, [])
  
let series = [
  {
    name: "Hospitals",
    data: apiData ? [apiData?.Jan, apiData?.Feb, apiData?.Mar, apiData?.Apr, apiData?.May, apiData?.Jun, apiData?.Jul, apiData?.Aug, apiData?.Sep, apiData?.Oct, apiData?.Nov, apiData?.Dec]: [],
      },
]

  return (
    <Col xxl='10' md='12' className='box-col-8 grid-ed-12'>
      <Row className='row'>
        <Col xxl='9' sm='8' className='box-col-6'>
          <HospitalsChart series={series} data={data} apiData={apiData} loading={loading} />
        </Col>
        <Col xxl='3' md='4' className='box-col-6'>
          <UsersShowCase series={ series = [{totalNurses: data?.totalNurses,totalUsers: data?.totalUsers,totalPatients: data?.totalPatients}]} />
        </Col>
      </Row>
    </Col>
  );
};

export default TopHeroSection;
