import Dashboard from "../../Dashboard";
import Roles from "../../Dashboard/Roles";
import Users from "../../Dashboard/Users";
import RegisterHospital from "../../Components/Widgets/General";
import DashBoardModules from "../../Dashboard/Modules";

export const AllRoutes = [
        {
          title: "Dashboard",
          icon: "home",
          type: "link",
          badge: "badge badge-light-primary",
          badgetxt: "5",
          active: false,
          element: <Dashboard/>,
          path:'/'
        },
  
        {
          title: "Register Hospitals",
          icon: "widget",
          type: "link",
          active: false,
          element: <RegisterHospital />,
          path: '/hospitals'
        },
        {
          title: "Roles",
          icon: "widget",
          type: "link",
          active: false,
          element: <Roles />,
          path: '/roles'
        },
        {
          title: "Users",
          icon: "widget",
          type: "link",
          active: false,
          element: <Users />,
          path: '/users'
        },
        {
          title: "Modules",
          icon: "widget",
          type: "link",
          active: false,
          element: <DashBoardModules />,
          path: '/modules'
        },
]