import React, { Fragment } from "react";
import { ToastContainer } from "react-toastify";
import {  Route, Routes} from "react-router-dom";
import '../assets/scss/sidebar.css'
import Taptop from "./TapTop";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Loader from "./Loader";
import Dashboard from "../Dashboard";
import {AllRoutes}  from "./Sidebar/Routes";
const AppLayout = ({ children, classNames, ...rest }) => {

  
  const layout1 = localStorage.getItem("sidebar_layout") || "compact-wrapper";
  const sideBarIcon = localStorage.getItem("sidebar_icon_type") || "stroke-svg";

  return (
    <Fragment>
      <Loader />
      <Taptop />
      <div className={`page-wrapper ${layout1}`} sidebar-layout={sideBarIcon} id="pageWrapper">
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
              <div className="page-body">
                <div>
                  <div>
                    <Routes>
                      <Route index key={1} path='/' name="Dashboard" element={<Dashboard />}/>
                      {/* <Route key={2} path='/hospitals' name="Register Hospital" element={<RegisterHospital/>} /> */}
                      {AllRoutes.map((route, index) => <Route key={index} name={route.title} path={route.path} element={route.element}  />)}
                    </Routes>
                  </div>
                </div>
              </div>
          <Footer />
        </div>
      </div>
      {/* <ThemeCustomize /> */}
      <ToastContainer />
    </Fragment>
  );
};
export default AppLayout;
