import {CircularProgress, Container} from "@mui/material";
import React, {useEffect, useState} from "react";
import axios from "../../config/server";
import DataTable from "react-data-table-component";
import toastr from "toastr";
import Switch from "react-switch";
import "./modules.css";

const DashBoardModules = () => {
  const [data, setData] = useState([]);
  const [switches, setSwitches] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [modules, setModules] = useState({
    App: "",
    Therapist: "",
  });

  async function fetchHospitalData() {
    try {
      const response = await axios.get("admin/hospitals");
      setData(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error?.response?.message);
    }
  }
  useEffect(() => {
    fetchHospitalData();
  }, []);
  const handleChange = async (nextChecked, rowId, module) => {
    try {
      setIsLoading(true);
      const response = await axios.patch(`hospitals/${rowId}`, {
        module,
        add: nextChecked,
      });
      if (response.status === 200) {
        setData(
          data.map(item =>
            item._id.toString() === rowId.toString() ? response.data.data : item
          )
        );
        setIsLoading(false);
        toastr.success(response?.data?.message);
      } else {
        toastr.error(response?.data?.message);
      }
      setSwitches(prevSwitches => ({
        ...prevSwitches,
        [rowId]: {
          ...prevSwitches[rowId],
          [module]: nextChecked,
        },
      }));
    } catch (error) {
      console.log("error", error);
      toastr.error(error?.response?.data?.message);
    }
  };
  const columns = [
    {
      name: "Hospital",
      center: true,
      selector: row => row.name,
    },
    {
      name: "Owner Name",
      center: true,
      selector: row => row.ownerName,
    },
    {
      name: "App Module",
      center: true,
      selector: row =>
        isLoading ? (
          <CircularProgress color="inherit" />
        ) : (
          <Switch
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={45}
            className="react-switch"
            id="material-switch"
            onChange={nextChecked => handleChange(nextChecked, row._id, "App")}
            checked={row?.modules?.includes("App") || false}
          />
        ),
    },
    {
      name: "Therapist Module",
      center: true,
      selector: row =>
        isLoading ? (
          <CircularProgress color="success" />
        ) : (
          <Switch
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={45}
            className="react-switch"
            id="material-switch"
            onChange={nextChecked =>
              handleChange(nextChecked, row._id, "Therapist")
            }
            checked={row?.modules?.includes("Therapist") || false}
          />
        ),
    },
    {
      name: "Patient Relation",
      center: true,
      selector: row =>
        isLoading ? (
          <CircularProgress color="success" />
        ) : (
          <Switch
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={45}
            className="react-switch"
            id="material-switch"
            onChange={nextChecked =>
              handleChange(nextChecked, row._id, "pr")
            }
            checked={row?.modules?.includes("pr") || false}
          />
        ),
    },
  ];

  return (
    <Container>
      <h1 className="text-dark text-center pt-4">Modules</h1>
      <DataTable
        columns={columns}
        highlightOnHover
        pointerOnHover
        data={data}
      />
    </Container>
  );
};

export default DashBoardModules;
