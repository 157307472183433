import React, { useState, useEffect } from 'react';
import axios from '../../config/server';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import { FaEdit, FaTrash } from "react-icons/fa";
import { MDBBtnGroup } from 'mdb-react-ui-kit';
import { Modal } from 'react-bootstrap';


const Users = () => {
    const [users, setUsers] = useState([]);
    const [newUser, setNewUser] = useState({});
    const [options, setOptions] = useState([]);
    const [roles, setRoles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedRole, setSelectedRole] = useState('');
    const [isUserDeleted, setIsUserDeleted] = useState(false);
    
    const handleDeleteUser = async (id) => {
        Swal.fire({
            title: 'Are You Sure?',
            text: 'You Will Not Be Able To Recover This Data!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it!',
            cancelButtonText: 'No, Cancel!',
            color: 'dark',
            confirmButtonColor: 'danger',
            reverseButtons: true
          }).then(async (result) => {
          if (result.isConfirmed) {
            console.log('====>id===>', id);
            try {
                const response = await axios.delete(`users/${id}`)
                console.log('====>response===>', response);
                if (response.status === 200) {
                    toast.success(response.data.message, toastOption);
                    setIsUserDeleted(true);
                }
            } catch (error) {
                toast.error(error.response.data.message, toastOption);
            }
    }
          });
        }
        const getData = async () => {
            const response = await axios.get('hospitals/')
            const resp = await axios.get('users/')
            setIsUserDeleted(false)
            setUsers(resp?.data?.data.map((item, index) => {
                return { ...item, index: index + 1,
                     hospital: response?.data?.data.filter((h) => h._id === item.hospitalId).map((h) => h.name),
                     actions:                     
                     <MDBBtnGroup key={item._id}>
                        <button
                        outline
                        color="dark"
                        className="my-1 py-2 px-4"
                        size="sm"
                         style={{borderRight: 'none'}}
                        onClick={() => handleEditUser(item)}
                        >
                        <FaEdit />
                        </button>
                        <button
                        outline
                        color="danger"
                        className="my-1 me-1 py-2 px-4"
                        size="sm"
                        style={{border: '1px solid #dc3545', borderTopRightRadius: '0.25rem', borderBottomRightRadius: '0.25rem'}}
                        onClick={() => handleDeleteUser(item._id)}
                        >
                        <FaTrash color='#dc3545' />
                        </button>
                    </MDBBtnGroup>
                      }
            }))
            setOptions(response?.data?.data)
        }
    useEffect(() => {
       
        getData();
    }, [isUserDeleted]);

    useEffect(() => {

        const getRoles = async () => {
            const response = await axios.get('roles/')
            setRoles(response?.data?.data)
        }
        getRoles();

    }, []);
    const toastOption = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    }
    const handleEditUser = (user) => {
        setSelectedUser(user);
        setShowModal(true);
    };
    
    
    const tableColumns = [
        {
            name: "Index",
            selector: "index",
            sortable: true,
        },
        {
            name: "Name",
            selector: "name",
            sortable: true,
        },
        {
            name: "Email",
            selector: "email",
            sortable: true,
        },
        {
            name: "Role",
            selector: "role",
            sortable: true,
        },
        {
            name: "Hospital",
            selector: "hospital",
            sortable: true,
        },
        {
            name: "Actions",
            selector: "actions",
            sortable: true,
        }
    ]

    const handleAddUser = async () => {
        try {
            const resp = await axios.post('users/user', newUser)
            console.log('====>resp===>', resp);
            if (resp.status === 201) {
                toast.success(resp.data.message, toastOption);
                setUsers(resp?.data?.data.map((item, index) => {
                    return { ...item, index: index + 1,
                         hospital: options.filter((h) => h._id === item.hospitalId).map((h) => h.name),
                         actions:                     
                     <MDBBtnGroup key={item._id}>
                        <button
                        outline
                        color="dark"
                        className="my-1 py-2 px-4"
                        size="sm"
                         style={{borderRight: 'none'}}
                        onClick={() => handleEditUser(item)}
                        >
                        <FaEdit />
                        </button>
                        <button
                        outline
                        color="danger"
                        className="my-1 me-1 py-2 px-4"
                        size="sm"
                        style={{border: '1px solid #dc3545', borderTopRightRadius: '0.25rem', borderBottomRightRadius: '0.25rem'}}
                        onClick={() => handleDeleteUser(item._id)}
                        >
                        <FaTrash color='#dc3545' />
                        </button>
                    </MDBBtnGroup>
                         }
                }));
                setNewUser({
                    name: '',
                    email: '',
                    password: '',
                    role: '',
                    hospitalId: ''
                });
            }
        } catch (err) {
            toast.error(err.response.data.message, toastOption);
        }
    };

    const handleEditRole = async () => {
        try {
            const resp = await axios.patch(`users/${selectedUser._id}`, {
                role : selectedRole
            })
            if (resp.status === 200) {
                toast.success(resp.data.message);
                getData();
                setSelectedUser(null);
                setSelectedRole('');
                setShowModal(false);                
            }else{
                toast.error(resp.data.message);
            }
        
        } catch (error) {
            console.log('====>error===>', error);
            toast.error(error.response.data.message);
        }
    }

    return (
        <Container className='mx-auto mt-5 pt-5'>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <h2>Add User</h2>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Name"
                        value={newUser.name}
                        onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                        style={{ margin: '10px 0', padding: '0px' }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Email"
                        value={newUser.email}
                        onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                        style={{ margin: '10px 0', padding: '0px' }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Password"
                        value={newUser.password}
                        onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                        style={{ margin: '10px 0', padding: '0px' }}
                    />
                </Grid>
                <Grid item xs={6} className='my-auto'>
                <Autocomplete
                    id="hospital-autocomplete"
                    options={options}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Choose Hospital..." />}
                    value={options.find(option => option._id === newUser.hospitalId) || null}
                    onChange={(event, newValue) => {
                        setNewUser({ ...newUser, hospitalId: newValue?._id });
                    }}
                />
                </Grid>
                <Grid item xs={6} className='my-auto'>
                <Autocomplete
                    id="role-autocomplete"
                    options={roles}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Choose Role..." />}
                    value={roles.find(role => role.name === newUser.role) || null}
                    onChange={(event, newValue) => {
                        setNewUser({ ...newUser, role: newValue?.name });
                    }}
                />
                </Grid>
            </Grid>
            <Button variant="outlined" className='mt-3' color='primary' onClick={handleAddUser}>Add User</Button>

            <Container className='mt-5'>
                <DataTable
                    data={users?? []}
                    columns={tableColumns}
                    striped={true}
                    center={true}
                    pagination
                // selectableRows
                // onSelectedRowsChange={handleRowSelected}
                // clearSelectedRows={toggleDelete}
                />
                <Modal centered show={showModal} onHide={() => setShowModal(false)}>
    <Modal.Header closeButton>
        <Modal.Title>Edit User Role</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {showModal &&  <Autocomplete
                id="role-autocomplete"
                options={roles}
                
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Role" />}
                value={roles.find(role => role.name === selectedUser?.role)}
                onChange={(event, newValue) => {
                    setSelectedRole(newValue?.name);
                }}
            />
}
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" style={{background:'gray', marginRight:'10px' ,color:'white'}} onClick={() => setShowModal(false)}>
            Close
        </Button>
        <Button variant="primary" style={{background:'purple', color:'white'}} onClick={() => handleEditRole()}>
            Save Changes
        </Button>
    </Modal.Footer>
</Modal>

            </Container>
        </Container>
    );
};

export default Users;
