
import axios from 'axios'
import socketIOClient from "socket.io-client";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
console.log('API URL', axios.defaults.baseURL);
if(sessionStorage.getItem("data")){
    const data = JSON.parse(sessionStorage.getItem("data"));
    axios.defaults.headers.common = {'Authorization': `bearer ${data.token}`}
}

const socket = socketIOClient(process.env.REACT_APP_API_SOCKET_URL, { path: process.env.REACT_APP_API_SOCKET_PATH});

export default axios;
export {socket}; 