import React, { useState, useEffect, useRef } from 'react';
import axios from '../../config/server';
import { Button, TextField, Grid } from '@mui/material';
import { Container } from 'reactstrap';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { toast } from 'react-toastify';
import { Col, Row, ToastContainer } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormGroup, Label, Input } from 'reactstrap';
import EditIcon from '@mui/icons-material/Edit';
import Swal from 'sweetalert2';



const Roles = () => {
    const [roles, setRoles] = useState([]);
    const [newRole, setNewRole] = useState('');
    const checkboxLabels = ['Dashboard', 'Registration', 'Working Days', 'Leave Days', 'Roaster', 'DTR', 'FTE', 'PayRoll', 'Revenue', 'PNL', 'Attendance', 'Messages', 'Settings', 'NAT', 'PR', 'Notifications', 'Users'];
    const [checked, setChecked] = useState(Array(checkboxLabels.length).fill(false));
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [editingRole, setEditingRole] = useState(null);
    const [editMode, setEditMode] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get('/roles')
            setRoles(response?.data?.data);
        }
        fetchData();
    }, []);


    toastr.options = {
        positionClass: "toast-top",
        hideDuration: 3000,
        timeOut: 3000,
    };

    const toastOption = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    }

    const tableColumns = [
        {
            name: "Index",
            selector: "index",
            sortable: true,
        },
        {
            name: "Role",
            selector: "name",
            sortable: true,
        },
        {
            name: "Permissions",
            selector: "permissions",
            sortable: true,
            with: '300px',
            height: '300px',
            align: 'center',
        },
        {
            name: "Actions",
            selector: "actions",
            sortable: true,
        },
    ]
    let data;
    const rolesData = roles?.map((item, index) => {
        return data = {
            index: index + 1,
            id: item._id,
            name: item.name,
            permissions: <p style={{ whiteSpace: 'pre-line', overflowWrap: 'break-word' }}> {item.permissions.map((item, index) => {
                return item + ', '
            })}</p>,
            actions: <><EditIcon style={{ color: 'purple', marginRight: '10px', cursor: 'pointer' }} onClick={() => handleEditRole(item._id)} /><DeleteIcon style={{ color: '#ff4141', cursor: 'pointer' }} onClick={() => handleDeleteRole(item._id)} /></>
        }
    })

    const handleNewRoleChange = (event) => {
        setNewRole(event.target.value);
    };
    const handleAddRole = async () => {
        if (newRole === '') {
            return toast.error('Please enter a role', toastOption);
        }
        if (selectedLabels.length === 0) {
            return toast.error('Please select at least one Permission', toastOption);
        }
        try {
            const response = await axios.post('roles/', { name: newRole, permissions: selectedLabels })
            if (response.status === 201) {
                toast.success(response.data.message, toastOption);
                setRoles([...roles, response.data.data]);
                setChecked(Array(checkboxLabels.length).fill(false));
                setSelectedLabels([]);
                setNewRole('');
            }
        } catch (err) {
            toast.error(err.response.data.message, toastOption);
        }

    };

    const handleDeleteRole = async (id) => {
        Swal.fire({
            title: 'Are You Sure?',
            text: 'You Will Not Be Able To Recover This Data!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it!',
            cancelButtonText: 'No, Cancel!',
            color: 'dark',
            confirmButtonColor: 'danger',
            reverseButtons: true
          }).then(async (result) => {
          if (result.isConfirmed) {
            try {
                const response = await axios.delete(`roles/${id}`)
                console.log('====>response===>', response);
                if (response.status === 200) {
                    toast.success(response.data.message, toastOption);
                    setRoles(roles?.filter(role => role._id !== id));
                }
            } catch (error) {
                toast.error(error.response.data.message, toastOption);
            }
    };
})}
    const handleEditRole = (id) => {
        setEditMode(true);
        const roleToEdit = roles.find((role) => role._id === id);
        setEditingRole(roleToEdit);
        const initialCheckedState = checkboxLabels.map((label) =>
            roleToEdit.permissions.includes(label)
        );
        setChecked(initialCheckedState);
        setSelectedLabels(roleToEdit.permissions);
        setNewRole(roleToEdit.name);

    };

    const handleUpdateRole = async () => {
        try {
            const response = await axios.patch(`roles/${editingRole._id}`, {
                name: newRole,
                permissions: selectedLabels,
            });

            if (response.status === 200) {
                toast.success(response.data.message, toastOption);

                setRoles((prevRoles) =>
                    prevRoles.map((role) =>
                        role._id === editingRole._id ? response.data.data : role
                    )
                );
                setEditingRole(null);
                setSelectedLabels([]);
                setNewRole('');
                setEditMode(false);
                setChecked(Array(checkboxLabels.length).fill(false));
            }
        } catch (err) {
            toast.error(err.response.data.message, toastOption);
        }
    };

    const handleCheckboxChange = (index) => {
        console.log('====>index===>', index);
        const newCheckedItems = [...checked];
        newCheckedItems[index] = !newCheckedItems[index];
        console.log('====>newCheckedItems===>', newCheckedItems);
        setChecked(newCheckedItems);
        const selectedLabelsCopy = [...selectedLabels];
        if (newCheckedItems[index]) {
            selectedLabelsCopy.push(checkboxLabels[index]);
        } else {
            const labelIndex = selectedLabelsCopy.indexOf(checkboxLabels[index]);
            if (labelIndex !== -1) {
                selectedLabelsCopy.splice(labelIndex, 1);
            }
        }
        setSelectedLabels(selectedLabelsCopy);
    };


    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <ToastContainer />

              
            <Grid container spacing={2} mt={5} sx={{ mx: 'auto' }} className='mt-5 pt-5'>
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        label="Role Name"
                        value={newRole}
                        onChange={handleNewRoleChange}
                        style={{ margin: '10px 0', padding: '0px' }}
                    />
                </Grid>
                <Grid item xs={4} sx={{ my: 'auto' }}>
                    <Button variant="contained" sx={{
                        background: 'white', color: '#7366ff', padding: '16px 3rem', '&:hover': {
                            background: 'white',
                        },
                    }} onClick={editMode ? handleUpdateRole : handleAddRole}>
                        {editMode ? 'Update Role' : 'Add Role'}
                    </Button>
                </Grid>
            </Grid>
            <Container>
                <Row>
                <div  className={`d-flex align-items-center justify-content-between my-auto`} style={{background: editMode ? 'purple' : '#f1f0ff'}}  >
                    <h6 className = "mt-2" style={{color: editMode ? 'white' : '#7366ff'}}>{editMode ? 'Update Permissions....' : 'Add Permissions....'}</h6>
                </div>
                    {checked.map((isChecked, index) => (
                        <Col xs={6} md={3} key={index}>
                            <FormGroup className='m-t-15 custom-radio-ml'>
                                <div className={`checkbox ${isChecked ? 'checkbox-success' : 'checkbox-dark'}`}>
                                    <Input
                                        id={`checkbox-default-${index}`}
                                        type='checkbox'
                                        checked={isChecked}
                                        onChange={() => handleCheckboxChange(index)}
                                    />
                                    <Label for={`checkbox-default-${index}`}>{checkboxLabels[index]}</Label>
                                </div>
                            </FormGroup>
                        </Col>
                    ))}
                </Row>
            </Container>
            <Container className='mt-5'>
                <DataTable
                    data={rolesData}
                    columns={tableColumns}
                    striped={true}
                    center={true}
                    pagination
                // selectableRows
                // onSelectedRowsChange={handleRowSelected}
                // clearSelectedRows={toggleDelete}
                />
            </Container>
        </>
    );
};

export default Roles;
