import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Signin from './Auth/Signin';
import { createBrowserHistory } from 'history';
import AppLayout from './Layout/Layout';

function getToken() {
  const tokenString = sessionStorage.getItem('data');
  const data = JSON.parse(tokenString);
  if (!data) return false;
  if (data.token === undefined) {
    return false
  } else {
    return data.token;
  }
}

const App = () => {
  
  const token = getToken();
  const history = createBrowserHistory();

  if (!token) {
    history.push("login");
    return <Signin />;
  }


  return (
    <div className='App'>


      <Routes history={history}>
        {!token ? 
            <Route path='/login' element={<Signin />} />  : 
            <Route path="*" element={<AppLayout />} />}
      </Routes>

    </div>
  );
};

export default App;
