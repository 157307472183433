import React, { Fragment, useState } from "react";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, P } from "../AbstractElements";
import { EmailAddress, Password, SignIn } from "../Constant";

import OtherWay from "./OtherWay";
import { ToastContainer, toast } from "react-toastify";
import axios from "../config/server";
import { createBrowserHistory } from 'history';

const Signin = () => {
  const history = createBrowserHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);


  const loginAuth = async (e) => {
    e.preventDefault();
    try {
      const LoginData = await axios.post("users/login", { email, password, user : 'SuperAdmin' })
      const data = LoginData?.data;
      toast.options = {
        positionClass: "toast-top-full-width",
        hideDuration: 300,
        timeOut: 3000,
      };
      if (data.status) {
        toast.success("Successfully logged in!..");
        sessionStorage.setItem("data", JSON.stringify(data?.data));
        history.push("/");
        history.go("/");
      } else {
        toast.error("You enter wrong password or username!..");
      }

    }
    catch (err) {
      toast.error(err.response?.data.message)
    }
  };

  return (
    <Fragment>
      <Container fluid={true} className="p-0 login-page">
        <Row>
          <Col xs="12">
            <div className="login-card">
              <div className="login-main login-tab">
                <Form className="theme-form">
                  <H4>Sign In</H4>
                  <P>{"Enter your email & password to login"}</P>
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input className="form-control" type="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <Label className="col-form-label">{Password}</Label>
                    <div className="position-relative">
                      <Input className="form-control" type={togglePassword ? "text" : "password"} onChange={(e) => setPassword(e.target.value)} value={password} />
                      <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                        <span className={togglePassword ? "" : "show"}></span>
                      </div>
                    </div>
                  </FormGroup>
              <div className="position-relative form-group mb-0">
                    <Btn attrBtn={{ color: "primary", className: "d-block w-100 mt-2", onClick: (e) => loginAuth(e) }}>{SignIn}</Btn>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default Signin;
