import React, { useState } from 'react';
import { Grid } from 'react-feather';
import { Link } from 'react-router-dom';
import { Image } from '../../AbstractElements';
import CubaIcon from '../../assets/images/logo/logo.png';

const SidebarLogo = ({  }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  const openCloseSidebar = () => {
    // const sidebar = document.querySelector('.sidebar-wrapper');
    // sidebar.classList.add('close_icon');

    setSidebarOpen(!isSidebarOpen);

    if (isSidebarOpen) {
      const sidebar = document.querySelector('.sidebar-wrapper')
      const pageHeader = document.querySelector('.page-header')
      const pageBody = document.querySelector('.page-body')
      sidebar.classList.add('close_icon')
      pageBody.style.marginLeft = '96px';
      pageHeader.style.marginLeft = '100px';
      pageHeader.style.width = '92%';
     }
     else{
      const sidebar = document.querySelector('.sidebar-wrapper')
      const pageHeader = document.querySelector('.page-header')
      const pageBody = document.querySelector('.page-body')
      const closedIcon = document.querySelector('.closed')
      closedIcon.style.color = 'black'
      sidebar.classList.remove('close_icon')
      pageHeader.style.marginLeft = '265px';
      pageHeader.style.width = 'calc(100% - 265px)';
      pageBody.style.marginLeft = '265px';
     }
  };



  return (
    <div className='logo-wrapper'>
      <Link to={`/`}>
        <img className = 'd-inline' style={{ width: '140px' }} src ={CubaIcon}  alt='logo' />
      </Link>
      <div className='back-btn' onClick={() => openCloseSidebar()}>
        <i className='fa fa-angle-left'></i>
      </div>
      <div className='toggle-sidebar' onClick={openCloseSidebar}>
        <Grid className={`status_toggle middle sidebar-toggle ${isSidebarOpen ? '' : 'closed'}`} />
      </div>
    </div>
  );
};

export default SidebarLogo;
