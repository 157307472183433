import React, { Fragment, useState,  useEffect } from 'react';
import { Col } from 'reactstrap';
import { AlignCenter } from 'react-feather';
import { Link } from 'react-router-dom';
import { Image } from '../../../AbstractElements';
import NotificationSlider from './NotificationSlider';

const Leftbar = () => {
  const [sidebartoggle, setSidebartoggle] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
      if (window.innerWidth <= 991) {
        toggleSidebar(true);
      } else {
        toggleSidebar(false);
      }
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  const toggleSidebar = (toggle) => {
    if (width <= 991) {
      setSidebartoggle(!toggle);
      document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper ';
      document.querySelector('.bg-overlay1').classList.add('active');
    } else {
      if (toggle) {
        setSidebartoggle(!toggle);
        document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper close_icon ';
      } else {
        setSidebartoggle(!toggle);
        document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper ';
      }
    }
  };

  return (
    <Fragment>
      <Col className="header-logo-wrapper col-auto p-0" id="out_side_click">
        <div className="logo-wrapper">
          <Link to={`/`}>
            <Image
              attrImage={{
                className: 'img-fluid for-light',
                src: require('../../../assets/images/logo/logo.png').default,
                alt: '',
              }}
            />
            <Image
              attrImage={{
                className: 'img-fluid for-dark',
                src: require('../../../assets/images/logo/logo_dark.png').default,
                alt: '',
              }}
            />
          </Link>
        </div>
        <div className="toggle-sidebar" onClick={() => toggleSidebar(sidebartoggle)} style={width <= 991 ? { display: 'block' } : { display: 'none' }}>
          <AlignCenter className="status_toggle middle sidebar-toggle" id="sidebar-toggle" />
        </div>
      </Col>
      {/* <Col xxl="5" xl="6" lg="5" md="4" sm="3" className="left-header p-0">
        <NotificationSlider />
      </Col> */}
    </Fragment>
  );
};

export default Leftbar;